import { customizeList, chatList, customizeDaysum, customizeRewards } from '@/services/api'

const newUserRewards = {
  namespace: 'newUserRewards',
  state: {
  },
  reducers: {
  },
  effects: {
    *customizeList({ payload }, { call, put }) {
      const { data = []} = yield call(customizeList, payload);
      return data || [];
    },
    *chatList({ payload }, { call, put }) {
      const { data = []} = yield call(chatList, payload);
      return data || [];
    },
    *customizeDaysum({ payload }, { call, put }) {
      const {data = {}} = yield call(customizeDaysum, payload);
      return data;
    },
    *customizeRewards({ payload }, { call, put }) {
      const res = yield call(customizeRewards, payload);
      return res;
    },
  }
};

export default newUserRewards;
