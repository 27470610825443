import { getTaskList, getLimitedTaskList, getLimitedTaskReward, fullTaskInfo, signInList } from '@/services/api';
import { Toast } from 'antd-mobile';

const task =  {
  namespace: 'newTaskCenter',
  state: {
    list: {},
    activityId: null,
    titleUrl: '',
    countDown: 0,
    eventTimeInfo: '',
    diamondReceiveToday: 0,
    diamondReceiveTotal: 0,
    rewardToday: 0,
    rewardTotal: 0,
    rewardList: [],
    rules: '',
    activityStatus: null,
    officialTask: {},
    signIn: {},
  },
  reducers: {
    updateList(state, { payload = [] }) {
      return {
        ...state,
        list: payload || [],
      };
    },
    updateOfficialTask(state, { payload = [] }) {
      return {
        ...state,
        officialTask: payload || {},
      };
    },
    updateLimitedInfo(state, { payload = {} }) {
      return {
        ...state,
        activityId: payload.activityId || 0,
        titleUrl: payload.titleUrl || '',
        countDown: payload.countDown || 0,
        eventTimeInfo: payload.eventTimeInfo || '',
        diamondReceiveToday: payload.diamondReceiveToday || 0,
        diamondReceiveTotal: payload.diamondReceiveTotal || 0,
        rewardToday: payload.rewardToday || 0,
        rewardTotal: payload.rewardTotal || 0,
        rewardList: payload.rewardList || [],
        rules: payload.rules || '',
        activityStatus: payload.activityStatus || 0,
      };
    },
    updateDayList(state, { payload }) {
      return {
        ...state,
        signIn: payload || {},
      }
    }
  },
  effects: {
    *getList({ payload }, { call, put }) {
      const { error, data } = yield call(getTaskList, payload);
      // const version = compareVersion(['1', '2', '0']);
      // const newData = {};
      // const osType = getOsType();
      // Object.entries(data || {}).forEach(([k, v]) => {
      //   const isIos = (osType !== 'ios' && osType !== 'mac');
      //   if (v[0].type === 2 && isIos) {
      //     newData[k] = v;
      //   } else if (v[0].type !== 2) {
      //     newData[k] = v;
      //   }
      // });
      if (error || !data) {
        yield put({
          type: 'updateList',
          payload: [],
        });
        Toast.show({
          content: error,
        });
        return false;
      }
      yield put({
        type: 'updateList',
        payload: data,
      });
      return true;
    },
    *getLimitedInfo({ payload }, { call, put }) {
      const { error, data } = yield call(getLimitedTaskList, payload);
      if (error || !data) {
        yield put({
          type: 'updateLimitedInfo',
          payload: [],
        });
        Toast.show({
          content: error,
        });
        return false;
      }
      yield put({
        type: 'updateLimitedInfo',
        payload: data,
      });
      return true;
    },
    *collect({ payload }, { call, select }) {
      const { activityId } = yield select(state => state.newTaskCenter);
      const { error, data, code } = yield call(getLimitedTaskReward, { ...payload, activityId });
      if (error) {
        return {
          success: false,
          error,
          code,
        };
      }
      if (data) {
        return {
          data,
          success: true,
        };
      } else {
        return false;
      }
    },
    *fullTaskInfo({ payload }, { call, put }) {
      const res = yield call(fullTaskInfo, payload);
      yield put({
        type: 'updateOfficialTask',
        payload: res?.data,
      });
      return res;
    },
    *signInList({ payload }, { call, put }) {
      const { data, error } = yield call(signInList, payload);
      if (!error) {
        yield put({
          type: 'updateDayList',
          payload: data
        })
      }
      return data;
    }
  },
};

export default task;
