import ajax from './en_US/ajax';
import refresh from './en_US/refresh';
import expection from './en_US/expection';
import country from './en_US/country';
import share from './en_US/share';

const EN_US = {
  ...ajax,
  ...refresh,
  ...expection,
  ...country,
  ...share,
  blank: '<p>No related content</p> <p>Please check other channels</p>',
  commonErr: '<p>System busy,</p><p>please try again later</p>',
  loading: 'Loading...',
  retry: 'Retry',
  viewMore: 'View more',
  more: 'more',
  'month.select': 'Select Month',
  hot: 'Hot',
  all: 'All',
  'month.select.day': 'Select Date',
  'coin.merchant.title': 'CoinSeller',
  'coin.merchant.set.password': 'Set your trading password',
  'coin.merchant.confirm.again': 'Confirm the transaction password again',
  'coin.merchant.enter.password': 'Enter your transaction password',
  'coin.merchant.enter.original.password': 'Enter the original password',
  'coin.merchant.enter.new.password': 'Enter new password',
  'coin.merchant.confirm.new.again': 'Confirm the new password again',
  'coin.merchant.change.password': 'Change\npassword',
  'coin.merchant.history.title': 'Transaction\nHistory',
  'coin.merchant.history': 'History',
  'coin.merchant.user': 'User',
  'coin.merchant.chat': 'Chat',
  'coin.merchant.coins.num': 'Coins Inventory:',
  'coin.merchant.transfer.coins': 'Transfer Coins',
  'coin.merchant.enter.user.id': 'Enter user id',
  'coin.merchant.search': 'Inquiry',
  'coin.merchant.not.found': 'User not found',
  'coin.merchant.enter.amount': 'Enter the amount',
  'coin.merchant.tips':
    'You are about to transfer an additional amount to the user, please confirm the name and the amount of coins again. This operation is irreversible, and the official is not responsible for any misoperation.',
  'coin.merchant.enter.transaction.password': 'Enter the transaction password',
  'common.cap.Confirm': 'Confirm',
  'coin.merchant.enter': 'Enter',
  'coin.merchant.to': 'To:',
  'coin.merchant.amount': 'Amount:',
  'coin.merchant.id': 'ID',
  'coin.merchant.password.tips': 'The password must be at least 8 characters , and must consist of capital and small letters and numbers.',
  'coin.merchant.password.error': 'Wrong Password.Please try again.',
  'coin.merchant.success': 'Remittance succeeded',
  'coin.merchant.list.title': 'Select amount',
  'coin.merchant.list.desc': 'Transfer coins to this user is equivalent to recharge, which will also increase vip experience.',
  'coin.merchant.transfer': 'Transfer',
  'coin.merchant.recharge.title': 'Coinseller\nRecharge',
  'coin.merchant.min.input': 'Minimum amount 1000',
  'coin.merchant.der.channel': 'Payment Method:',
  'coin.merchant.der.channel.tips': 'If you cannot complete the payment through the following methods, please contact the WhatsApp of the official team to inquire about the purchase: ',
  'coin.merchant.der.channel.tips.link': 'copy',
  'coin.merchant.der.current.country': 'Currently using the payment channel of',
  'coin.merchant.der.change.country': 'Select country',
  'coin.merchant.der.modal.title': 'Method of Payment',
  'common.cap.Recharge': 'Recharge',
  'coin.merchant.der.id': 'TalkTalk ID',
  'coin.merchant.der.name': 'Your TalkTalk Nickname:',
  'coin.merchant.der.error': '*No Results Found',
  'coin.merchant.der.amount': 'Recharge Amount:',
  'coin.merchant.der.redeem': 'Redeem',
  'coin.merchant.der.fail': 'Fail to Recharge',
  'coin.merchant.der.fail.tips': 'Failed to recharge your account, please contact us at: TT_support@molelive.com if you have any questions!',
  'coin.merchant.der.complete': 'Recharge Complete',
  'coin.merchant.der.complete.tips': '{number} coins have been successfully recharged to {name}! Please log in to enjoy more fun!',
  'coin.merchant.der.another': 'Recharge Another Account',
  'coin.merchant.der.more': 'Recharge More',
  'coin.merchant.der.problem': 'Encounter Problems?',
  'coin.merchant.der.bind.phone': 'Bind mobile number',
  'coin.merchant.der.sms.code': 'Enter verification code',
  'coin.merchant.der.sms.tips': 'An abnormal activity has been detected. To ensure the security of your account, please finish the verification process below.',
  'coin.merchant.der.bind.phone.placeholder': 'Phone Number',
  'coin.merchant.der.resend': 'Resend',
  'common.cap.Continue': 'Continue',
  'coin.merchant.der.code.tips': 'If you cannot receive the verification code, please contact the WhatsApp of the official team: ',
  'total.rank.gift': 'WEEKLY<br />STAR',
  'total.rank.hour': 'Hourly',
  'total.rank.day': 'Daily',
  'total.rank.week': 'Weekly',
  'total.rank.month': 'Monthly',
  'total.rank.last.hour': 'Last Hour TOP3',
  'total.rank.last.day': 'Last Day TOP3',
  'total.rank.last.week': 'Last Week TOP3',
  'total.rank.last.month': 'Last Month TOP3',
  'total.rank.this.hour': 'This Hour',
  'total.rank.this.day': 'This Day',
  'total.rank.this.week': 'This Week',
  'total.rank.this.month': 'This Month',
  'total.rank.gap': 'Gap{X}',
  'total.rank.hide': 'Hide in Rank',
  'total.rank.hide.name': 'Mr.Mystery',
  'total.rank.hide.info': 'Hidden Information',
  'total.rank.help.title': 'Ranking Rules',
  'total.rank.help': `<h1>Ranking Type</h1>
  <p>Rank &lt;Room&gt;: During the time period, all rooms will be ranked according to the amount of coins gifts sent in the room.</p>
  <p>Rank &lt;GIFTER&gt;: During the time period, all users will be ranked according to the amount of coins gifts sent by the user.</p>
  <p>Rank &lt;CHARMER&gt;: During the time period, all users will be ranked according to the amount of coins gifts you received.</p>
  <h1>Rank Time Cycle</h1>
  <p>The ranking cycle is usually hours, days, weeks, and months.</p>
  <p>The Rank shows the top 3 of the previous cycle, as well as the top 50 of the current cycle, updated in real time.
    (With a delay of less than 1 minute)</p>
  <p>Refresh Time (UTC+3): Daily Rank refresh at 5am; Weekly Rank refresh at 5am every Monday; Monthly Rank refresh at 5am on the 1st of every month.</p>
  <h1>Privacy Protect</h1>
  <p>Some Ranks won't show specific numbers; </p>
  <p>However, the difference between the previous ranking is displayed; </p>
  <p>The top 3 won't show the difference between each other.</p>`,
  'total.rank.help.eu.ios.hide': `
  <h1>Rank Time Cycle</h1>
  <p>The Rank shows the top 3 of the previous cycle, as well as the top 50 of the current cycle, updated in real time.
    (With a delay of less than 1 minute)</p>
  <p>Refresh Time (UTC+3): Daily Rank refresh at 5am; Weekly Rank refresh at 5am every Monday; Monthly Rank refresh at 5am on the 1st of every month.</p>
  <h1>Privacy Protect</h1>
  <p>Some Ranks won't show specific numbers; </p>
  <p>However, the difference between the previous ranking is displayed; </p>
  <p>The top 3 won't show the difference between each other.</p>`,
  'total.rank.help.eu': `<h1>Ranking Type</h1>
  <p>Rank &lt;GIFTER&gt;: During the time period, all users will be ranked according to the amount of coins gifts sent by the user.</p>
  <p>Rank &lt;CHARMER&gt;: During the time period, all users will be ranked according to the amount of coins gifts you received.</p>
  <h1>Rank Time Cycle</h1>
  <p>The Rank shows the top 3 of the previous cycle, as well as the top 50 of the current cycle, updated in real time.
    (With a delay of less than 1 minute)</p>
  <p>Refresh Time (UTC+3): Daily Rank refresh at 5am; Weekly Rank refresh at 5am every Monday; Monthly Rank refresh at 5am on the 1st of every month.</p>
  <h1>Privacy Protect</h1>
  <p>Some Ranks won't show specific numbers; </p>
  <p>However, the difference between the previous ranking is displayed; </p>
  <p>The top 3 won't show the difference between each other.</p>`,
  'common.cap.Ranking': 'Ranking',
  'total.rank.room': 'ROOM',
  'total.rank.sent': 'GIFTER',
  'common.up.RECEIVED': 'CHARMER',
  'fission.relation.by.amount': 'By Amount',
  'fission.relation.by.time': 'By Time',
  'total.rank.daily': 'Daily',
  'common.cap.Countdown': 'Countdown',
  'common.up.RULES': 'RULES',
  'common.up.DAYS': 'DAYS',
  'common.up.HOURS': 'HOURS',
  'common.up.MINS': 'MINS',
  'common.cap.Successfully.Collected': 'Successfully Collected',
  'common.cap.Countdown.to.Start': 'Countdown To Start',
  'common.cap.Day': 'Day',
  'common.cap.Hour': 'Hour',
  'common.cap.Min': 'Min',
  'common.cap.Sec': 'Sec',
  'common.cap.Rules': 'Rules',
  'common.cap.event.over': 'The event is over',
  'recharge.event.tab.1': 'Recharge Progress',
  'recharge.event.amount.reached': 'Amount of coins reached',
  'recharge.event.ranking.list': 'Ranking List',
  'recharge.event.group': 'Group',
  'recharge.event.next.1': 'Only need',
  'recharge.event.next.2': 'coins to reach the next level',
  'recharge.event.rewards.for': 'Rewards For',
  'recharge.event.trouble': 'Are you having trouble with recharging?',
  'recharge.event.done': 'Congratulations!You have reached the highest level! Enjoy your rewards now!',
  'fission.trouble': 'Are you having trouble with recharging?',
  'common.cap.No': 'No',
  'common.cap.Unfortunately.Yes': 'Unfortunately Yes',
  'common.cap.Waiting': 'Waiting',
  'common.cap.update': 'Please update version to use new features',
  'common.cap.Customer.Server': 'Customer Service',
  'common.cap.Ranking.click': 'Click',
  'common.cap.Ranking.click.text': 'to check different prizes',
  'common.cap.Collect': 'Collect',
  'common.cap.Collected': 'Collected',
  'common.cap.Limited.Gifts': 'Limited Gifts',
  'family.football.target': 'Coins Rewards Target',
  'dream.city.rule.reward': 'Rules&\nRewards',
  'dream.city.city.map': 'City Map',
  'dream.city.rules': 'Event Rules',
  'dream.city.supply.reward': 'Supply Station Rewards',
  'dream.city.wealth': 'Wealth',
  'dream.city.charm': 'Charm',
  'dream.city.wealth.rank': 'Wealth',
  'dream.city.charm.rank': 'Charm',
  'dream.city.current.coins': 'Current Coins',
  'dream.city.current.diamonds': 'Current Diamonds',
  'dream.city.go': 'GO',
  'dream.city.will.get': 'You will get',
  'dream.city.received': 'Congratulations! You have received',
  'dream.city.top5': 'Top 5 users who reach the supply station at the sonnest will get the supply rewards.',
  'dream.city.waiting': 'waiting',
  'common.cap.Go': 'Go',
  'lucky.darw.ruls.1': 'Send gifts inside the chatrooms and get a chance to enter the draw',
  'lucky.darw.ruls.2': 'Every 5000 coins worth of gifts give you 1  draw',
  'lucky.darw.ruls.3': 'For example : 50,000 coins = 10 draws , 100,000 coins = 20 draws . Send more gifts to get more draws',
  'lucky.darw.ruls.4': "If the day end and you didn't use your draws , all your draws will become 0 after the event done",
  'lucky.darw.number': 'the number of my draws',
  'lucky.darw.reset.time': 'Countdown',
  'lucky.darw.ranking': 'Draw Ranking',
  'lucky.darw.use': 'GO TO USE',
  'lucky.darw.no.times': 'The current number of draw is not enough, go to the room and send gifts',
  'lucky.darw.end': 'Event not in progress',
  'lucky.darw.coin.one.times': 'Draw 1 time costs',
  'lucky.darw.coin.draw': 'Draw',
  'lucky.darw.coin.more.times': 'times will cost',
  'lucky.darw.coin.more.will.cost': 'will cost',
  'fission.coins': 'coins',
  'common.recharge.text': 'Coins are not enough. Do you want to recharge?',
  'fission.cancel': 'Cancel',
  'lucky.darw.title': 'Lucky Draw',
  'gacha.play.3': 'time',
  'gacha.play.1': 'Open',
  'gacha.play.2': 'times',
  'common.cap.Congratulations': 'Congratulations',
  'common.up.OK': 'OK',
  'lucky.darw.coin.one.times.two': 'Choose and click Go',
  'common.Cap.Dating': 'Dating',
  'cp.list.limit.tips': 'Send limited gifts to reach the level can unlock the dating location.',
  'cp.list.unlock.contributor': 'Location Contributor',
  'common.Cap.Top1': 'Top1',
  'cp.list.cur.reward.text': 'Unlock this location to receive the following rewards.',
  'iftar.total.ranking': 'Total Ranking',
  'iftar.congratulations.unlocking': 'Congratulations on your participation in unlocking the dish:',
  'iftar.you.received': 'You have received:',
  'activity.gift.list.send': 'Contributor',
  'iftar.daily.ranking': 'Daily Ranking',
  'iftar.reward.target': 'Coins Rewards Target',
  'common.cap.Rewards': 'Rewards',
  'common.up.SECS': 'SECS',
  'lucky.recharge.draw.start': 'Start lottery',
  'lucky.recharge.draw.num': 'my available raffle tickets',
  'lucky.recharge.draw.record': 'View my winning record',
  'lucky.recharge.tab.wheel': 'Fortune wheel',
  'lucky.recharge.tab.grid': 'Fortune price pool',
  'lucky.recharge.wheel.tip.text.1': 'Every time you recharge to a certain amount, you can get a lottery ticket, 100% winning!',
  'lucky.recharge.wheel.tip.text.2': 'The more draws, the greater the chance of winning the jackpot!',
  'lucky.recharge.ticket': 'raffle\nticket',
  'lucky.recharge.recharged': 'Recharged',
  'lucky.recharge.recharge.next': 'Recharge {N}<div></div> to get {X} lottery tickets',
  'lucky.recharge.recharge.done': 'You have won all the spinner tickets',
  'lucky.recharge.hide.info': 'hide personal information',
  'lucky.recharge.recharge.gift.bag': 'Recharge\npackage',
  'lucky.recharge.recharge.gift.bag.text': 'Recharge {N} every day to get',
  'lucky.recharge.recharge.gift.bag.text.1': 'Prize pool lottery ticket*1',
  'lucky.recharge.recharge.no': 'Insufficient raffle tickets',
  'common.Cap.Uncompleted': 'Uncompleted',
  'common.Collect': 'Collect',
  'break.through.text.14': 'Congratulations! You have been awarded',
  'world.cup.records': 'Records',
  'world.cup.time': 'Time',
  'common.number': 'Number',
  'recharge.result.pending': 'Operation complete',
  'recharge.result.tips': "If the coins do not arrive in time, please don't worry, there may be network delays. If you have any questions, please contact SOYO official customer service.",
  'recharge.result.tips.click': 'Click to return to APP',
  'new.task.go': 'Go',
  'new.task.done': 'Done',
  'task.center.title': 'Task Center',
  'task.center.common.level': 'Level',
  'common.cap.Event.To.Start': 'Event is about to start',
  'task.center.limit.task': 'Limited Task',
  'task.center.normal.task': 'Normal Task',
  'task.center.limit.today': 'Today',
  'task.center.limit.Total': 'Total',
  'task.center.limit.Diamonds.Received': 'Diamonds Received',
  'task.center.limit.Reward.Received': 'Reward Received',
  'task.center.limit.receive': 'Receive',
  'task.center.limit.extra': 'Extra',
  'task.center.limit.to.start': 'Event has not started yet',
  'gift.wall.title': 'Gifts',
  'gift.wall.help.text1': 'Gifts that exceed {num} coins at a time will appear on the gift wall',
  'gift.wall.help.text2': 'The gift that has more coins will be displayed in the first rankings',
  'gift.wall.help.text3': 'The record on the gift wall will be kept for 24 hours',
  'level.user': 'User Level',
  'level.halo': 'Halo Level',
  'level.halo.question1.title': 'Benefits of High Level',
  'level.halo.question1.answer1': 'Higher the Level, Brighter the Halo',
  'level.halo.question2.title': 'Level Icon Display',
  'level.halo.question2.answer1': 'Higher the level, More unique the icon',
  'level.halo.question3.title': 'Level Up Reward',
  'level.halo.question3.answer1': 'Level Up To Get Amazing Rewards',
  'level.halo.question4.title': 'Ways to Level Up',
  'level.halo.question4.answer1': `Receive gift worth 1 coin, Gain 1 exp.
  Higher the exp, Faster the upgrade.`,
  'level.user.question1.title': 'Benefits of High Level',
  'level.user.question1.answer1': 'You will get special items and reward.',
  'level.user.question2.title': 'Ways to Level Up',
  'level.user.question2.answer1': 'Chat in the room, play games and send gifts',
  'level.user.question2.answer2': 'Complete daily tasks,  gain experience to level up',
  'level.user.question2.answer7': 'Have party in room',
  'level.user.question2.answer8': 'Send gift by coins',
  'level.user.question2.answer9': 'Complete tasks and claim EXP',
  'level.user.question3.title': 'Level Icon Display',
  'level.user.question3.answer1': 'Higher the level, More unique the icon',
  'level.user.question4.title': 'Level Up Reward',
  'level.user.question4.answer1': 'Level Up To Get Amazing Rewards',
  'level.user.level': 'Level',
  'level.user.icon': 'Icon',
  'level.user.tips': 'As you continue to gain experience points, your level will gradually increase',
  'level.user.way.up': 'Ways to increase experience points:',
  'about.badges.title': 'About Badges',
  'about.badges.0': 'Wear Badge',
  'about.badges.1': 'All badges can be worn, and up to five badges can be worn at the same time.',
  'about.badges.2': 'Honor Badge',
  'about.badges.3': 'The badges unlocked by the cumulative monthly values are counted on the 1st of each month whether they are owned or not. The validity period lasts for one month.',
  flowWater: 'Coins Details',
  coinBack: 'Coins Returned',
  tips: 'No more data',
  'vip.privileges.title.privacy': 'Privacy Security',
  'vip.privileges.label.privacy': 'Others cannot search you/your voice room',
  'vip.privileges.title.invisible': 'Invisble Mode',
  'vip.privileges.label.invisible': "Stay invisble and hide yourself in others' recommendations",
  'vip.privileges.title.blockMessages': 'Quiet Mode',
  'vip.privileges.label.blockMessages': "Block Strangers' Messages and stay undisturbed.",
  'vip.privileges.title': 'VIP Privileges',
  'vip.privileges.activate': 'VIP{N} Activate',
  'vip.privileges.stealth.text': 'Hide my access history',
  'vip.privileges.stealth.open': 'Open',
  'vip.privileges.stealth.close': 'Close',
  'vip.privileges.title.icon': 'VIP Label',
  'vip.privileges.label.icon': 'Remarkable VIP lable, exclusive identity of VIP users, shows your honour.',
  'vip.privileges.title.visitor': 'Visiting Traces',
  'vip.privileges.label.visitor': "View all visitor traces so you don't miss any interested person and fans.",
  'vip.privileges.title.list': 'Priority Display',
  'vip.privileges.label.list': 'Priority display of search results.',
  'vip.privileges.title.follower': 'Higher Following Upper Limit.',
  'vip.privileges.label.follower':
    'You can become VIP and add more friends. Non-VIP can follow 1000 people. VIP1-3 will increase 1000 by each, VIP4-6 will increase 2000 by each, VIP7 and above will increase 3000 by each.',
  'vip.privileges.title.sign': 'VIP Check-in Rewards',
  'vip.privileges.label.sign': 'VIP can claim double check-in rewards',
  'vip.privileges.title.stealth': 'Invisible Visit',
  'vip.privileges.label.stealth': 'When you visit other users, no history will be recorded',
  'vip.privileges.title.name': 'Username highlight',
  'vip.privileges.label.name': 'Users will get special effects to decorate the username',
  'vip.privileges.title.avatar': 'VIP Frame',
  'vip.privileges.label.avatar': 'Exclusive frame to show your identity.',
  'vip.privileges.title.bubble': 'VIP Chat Bubble',
  'vip.privileges.label.bubble': 'Exclusive chat bubble makes your speech stand out.',
  'vip.privileges.title.room': 'VIP Mini Card',
  'vip.privileges.label.room': 'Exclusive vip room card',
  'vip.privileges.title.picture': 'Exclusive mini card to highlight your honour.',
  'vip.privileges.label.picture': 'The option to send images on the bullet chat',
  'vip.privileges.title.message': 'Top Message List',
  'vip.privileges.label.message': "You can put other users' dialogs on top of the message list.",
  'vip.privileges.title.vehicle': 'VIP Vehicle',
  'vip.privileges.label.vehicle': 'Exclusive VIP vehicles',
  'vip.privileges.title.rank': 'Hide Identity',
  'vip.privileges.label.rank': 'You can turn on the [Hide Identity]button to appear as a mystery man on the rankings.',
  'vip.privileges.title.broadcast': 'App Notification',
  'vip.privileges.label.broadcast': 'When you reach VIP8 and above, the upgrade will trigger app notification.',
  'vip.privileges.title.id': 'Exclusive ID',
  'vip.privileges.label.id':
    "Get your exclusive ID and let others remember you at a glance! VIP8: ABABAB(A and B should be different) VIP9: AAABB(A and B should be different) VIP10: ABAB(A and B should be different) VIP11: AABB(A and B should be different) All the form can't start with 0. You need to contact VIP customer service to get exclusive ID.",
  'vip.privileges.title.protection': 'Prevent Removal',
  'vip.privileges.label.protection': 'Never worry about being removed from mic or any rooms.',
  'vip.privileges.title.gif': 'Customized Dynamic Frame',
  'vip.privileges.label.gif':
    'Custimized your exclusive dynamic frame. When you reach VIP9, please contact Customer Service to get Customized Dynamic Frame,each user only has one chance to customize.',
  'vip.privileges.title.gift': 'Customized Gifts',
  'vip.privileges.label.gift': 'Custimized your exclusive gift. When you reach VIP10, please contact Customer Service to get Customized Gifts,each user only has one chance to customize.',
  'vip.privileges.title.exclusivevehicles': 'Customized Vehicle',
  'vip.privileges.label.exclusivevehicles':
    'Custimized your exclusive mount. When you reach VIP11, please contact Customer Service to get Customized Vehicle,each user only has one chance to customize.',
  'vip.privileges.title.open': 'Customized Open Page',
  'vip.privileges.label.open':
    'Others can automatically see you once open the app, the unbeatable glory! When you reach VIP12, please contact Customer Service to get a 24h Customized Open Page,each user only has one chance to customize.',
  'vip.privileges.update.info': 'The vip system has been updated, your version is too low to view the latest features. Please update to the latest version and enjoy the new vip function!',
  'aristocracy.vip.title': 'Aristocracy',
  'vip.help.1': 'VIP Rules',
  'vip.help.2': 'What is the VIP?',
  'vip.help.3': 'VIP is the special class in SOYO. It includes 11 identify levels. You can have the corresponding privileges after you own a VIP.',
  'vip.help.4': 'How to upgrade VIP level?',
  'vip.help.5': `1.Recharge $1 can get 1 VIP EXP.</br>
  2.Your VIP level will upgrade immediately when VIP EXP reaches the upgrade requirement in that month.</br>
  3.From the 1st day of each month, each person's initial VIP EXP will be reset to 0. And your VIP level will be decided by accumulated EXP amount.`,
  'vip.help.6': 'Upgrade Vip Level Rules',
  'vip.help.7': 'Upgrade Rules',
  'vip.help.8':
    'Once you reach the upgrade conditions this month, your VIP level will be updated immediately.And the VIP level will be maintained till the end of the next month. For example, the user reached VIP5 on March 10 and his VIP5 will continute until April 30.',
  'vip.help.9': 'Retain/Downgrade Rules',
  'vip.help.10':
    'If your accumulated EXP value reaches the requirements for your current VIP level in this month, you will keep your VIP level in the next month; otherwise, your VIP level will be downgraded next month.For example: if you are VIP5 in May, but you only accumulated VIP3 in May, then your VIP level will be downgraded to VIP3 from June.',
  'vip.help.11': 'VIP Level Rebate',
  'vip.help.12': 'Based on the VIP Level on the 1st of day each month, we will send a cretain amount of VIP EXP to users according to their VIP Levels on the 1st day of each month.',
  'vip.help.13': 'Level',
  'vip.help.14': 'EXP Value Interval',
  'vip.help.15': 'EXP Value Rebate',
  'vip.help.update.info': 'The vip system has been updated, your version is too low to view the latest features. Please update to the latest version and enjoy the new vip function!',
  'to.exchange.page.button': 'Exchange',
  details: 'Diamonds Details',
  miss: 'Missed Diamonds',
  profit: 'Income Statistics',
  missToday: 'Missed-Today',
  cumulativeMiss: 'Missed-Total',
  distributionOfDiamondIncome: 'Diamonds income distribution',
  getDiamondsMonth: 'Diamonds this month：',
  getDiamondsDay: 'Diamonds today：',
  cancel: 'Cancel',
  confirm: 'Confirm',
  daily: 'Daily',
  month: 'Month',
  tips: 'No more data',
  voiceChat: 'User Ratings <br /> for Voice Chat with the anchor',
  voiceChat1: 'User Ratings <br /> for Voice Chat with me',
  quickCall: 'User Ratings <br /> for Quick Call with the anchor',
  quickCall1: 'User Ratings <br /> for Quick Call with me',
  chatting: 'Message chat performance',
  chatting1: 'My chat performance',
  chatSpeed: 'Reply speed',
  chatSpeed1: 'My speed of replying messages',
  like: 'like',
  boring: 'boring',
  Hate: 'Hate',
  chatConversation: '💬 Chat Rounds',
  reached: '🙋‍♀️ Number of users',
  count: '🙋‍♀️ Messages',
  speed: '⏰ Speed of replying messages',
  quality: 'Chat quality',
  speed: 'Reply speed',
  call: 'Call & Rating',
  currentCycle: 'Current Cycle',
  speedTitle: 'Reply to Message Income Rules',
  roundTitle: 'Chat Rounds Income Rules',
  modalTips: 'Faster you reply to messages will improve user experience, and the more diamonds you earn.',
  modalTipsTwo: 'The higher quality of your reply content and more chat rounds will improve user experience, and the more diamonds you earn:',
  modalSpeed: 'Response speed',
  modalIncome: 'Income Rate',
  modalRound: 'Chat rounds',
  modalReward: 'Extra rewards',
  modalBtn: 'Continue To Chat',
  cycleDiamonds: 'Diamonds this cycle',
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
  'my.earning.title': 'My Earnings',
  'my.earning.tips': 'Diamond balance',
  'my.earning.daimonds': 'diamonds',
  'my.earning.withdrawal.rules': 'Withdrawal Rules',
  'my.earning.salary.rules': 'Salary Rules',
  'ranking.daily': 'Daily',
  'ranking.weekly': 'Weekly',
  'ranking.contributed': 'Contributed:',
  'ranking.blank.daily': 'No one has sent gifts today',
  'ranking.blank.weekly': 'No one has sent gifts this week',
  'lucky.packet.title': 'Lucky packet',
  'lucky.packet.1': 'Send lucky packet',
  'lucky.packet.2': 'All users in the room can send and claim lucky packet',
  'lucky.packet.3': 'When there are many lucky packets in a room, they will be stacked and displayed. The earlier the lucky packets are sent, the more shown at the top.',
  'lucky.packet.4': 'Restrictions and Conditions',
  'lucky.packet.5': '1.Only supports sending coins.',
  'lucky.packet.6':
    '2.-The range of the amount of coins that can be issued: 1000~9999999\nThe default amount of coins is 3000 coins.\n  -Number of people that can be issued: 5-500\nThe default number is 10 people',
  'lucky.packet.7': '3.The per capita amount (amount of coins/number of people) needs to be more than or equal to 100 coins.',
  'lucky.packet.8':
    '4.When the amount of the packet is more than 10000 coins and the number of people is more than 10 will trigger world notification, and a special icon will be displayed in the room list.',
  'lucky.packet.9': 'How to play',
  'lucky.packet.10': '1.Lucky packet with password\n-You need to send the password in the room to grab it.',
  'lucky.packet.11': '2.Lucky packet requires followers \n-You need to follow the lucky packet sender to grab it.',
  'lucky.packet.12': 'Common Issues',
  'lucky.packet.13': '1.When the packet triggers the world notification or when the lucky packet icon is displayed, other people can enter your room by clicking on it.',
  'lucky.packet.14': '2.If you locked your room, the world notification and icon will not appear.',
  efficient_text_2: 'Effective time',
  efficient_text_5: 'Effective day',
  efficient_text_6: '1. Valid time in the room',
  efficient_text_7:
    'In the voice room, the time on the mic - the time off the mic> = %$ min is an effective time on the mic. We encourage the person on the mic to take the initiative to chat and interact with good friends for a long time, such as:',
  efficient_text_8: 'Time on the mic',
  efficient_text_9: 'Time off the mic',
  efficient_text_10: 'Whether reached the standard',
  efficient_text_11: 'Explain',
  efficient_text_12: 'Reach the standard',
  efficient_text_13: 'Did not reach the standard',
  efficient_text_14: 'If time on the mic lasts till the next day, it is counted as the time for the previous day',
  efficient_text_15: 'Time off the mic -Time on the mic <%$min',
  efficient_text_16: 'Time off the mic-Time on the mic> = %$min',
  efficient_text_17: '12/1 9:00:00 Time on the mic did not reach the standard, it cannot be counted as an effective time',
  efficient_text_18: '12/1 23:00:00 Time on the mic continued till 12/2 and lasted 90 min in a row, so it is counted as time during 12/1',
  efficient_text_19: '2. Effective days of the room',
  efficient_text_20:
    'In the voice room, all the effective durations are added during the day> = %$ min is an effective day. The higher the valid number of days, the higher the activity, and the activity rate directly affects the rewards you get.',
  efficient_text_21: 'Total effective time',
  efficient_text_22: '12/1  3 times on the mic during the day: 15min +35min +90min. Because 15 min does not meet the standard, the valid time = 90 +35 = 125min = 02H 05min',
  efficient_text_23: '12/1 The valid duration of the day in total = 02H 05min> = %$min, which is counted as a valid day',
  efficient_text_24: '3. Time on the mic',
  efficient_text_25:
    'The time on the mic must be effective time, and the effective time must be> = %$min. The time on the mic only indicates the time of chatting on the mic in the room. It will be considered as an effective time when the time on the mic> = %$min, such as:',
  efficient_text_26: '4. Data details',
  efficient_text_27: 'The data will be displayed according to the selected cycle',
  efficient_text_28: 'Diamonds',
  efficient_text_29: 'Chat',
  efficient_text_30: 'Voice Room',
  efficient_text_31: 'Details',
  efficient_text_32: 'Date',
  efficient_text_33: 'Time on the mic',
  efficient_text_34: 'Effective time of the Voice Room',
  efficient_text_35: 'Effective days of the Voice Room',
  share_text23: 'Download',
  share_text24: 'Join the room',
  share_text25: "I'm in this room, come and join us",
  share_text26: 'Room ID',
  OfficialTask_Card_common_text1: 'Official Task',
  OfficialTask_Card_common_text2: 'After completing the official reward task, you can get maximum {%s} diamonds!',
  OfficialTask_dailyCard_text1: 'Daily diamond income rewards ({%s1}/{%s2})',
  OfficialTask_dailyCard_text2: 'Daily diamond income ({%s1}) reaches {%s2} 💎, get {%s3} 💎',
  OfficialTask_dailyCard_text3: 'Daily diamond income rewards ({%s3}) reaches {%s1} 💎, get {%s2} 💎',
  OfficialTask_dailyCard_text4: 'Reward Time',
  OfficialTask_dailyCard_text5: 'Go',
  OfficialTask_PeriodCard_text1: 'Work Period Diamond Reward ({%s1}/{%s2})',
  OfficialTask_PeriodCard_text2: 'Work period diamond reward ({%s3})reaches {%s1} 💎, get {%s2} 💎',
  OfficialTask_ActiveCard_text1: 'Work period active reward',
  OfficialTask_ActiveCard_text2: 'Cumulative login in the cycle {%s1} days ({%s2}/{%s3})',
  OfficialTask_ActiveCard_text3: 'If daily diamond income reaches  {%s1} 💎, and you can get {%s2} 💎. (Today: {%s3}/{%s4})',
  OfficialTaskPic_Level_text1: 'Daily Diamond Income Rewards (IM Message)',
  OfficialTaskPic_Level_text2: 'Level',
  OfficialTaskPic_Level_text3: 'Diamond Target',
  OfficialTaskPic_Level_text4: 'Diamond Reward',
  OfficialTaskPic_Level_text5: 'Daily Diamond Income Rewards（Voice room gifts : normal voice room + family voice room)',
  OfficialTaskPic_Level_text6: 'Work Period Diamond  Income Rewards (IM message + all gifts received)',
  OfficialTaskPic_Level_text7: 'Work Period Active Rewards',
  OfficialTaskPic_Level_text8: 'Log in for {%s1} days and get {%s2} diamonds every day',
  OfficialTaskPic_Level_text9: 'Requirement',
  OfficialTaskPic_Level_text10: 'Diamond Reward',
  OfficialTaskPic_Level_text11: 'Notice：',
  OfficialTaskPic_Level_text12: '1. Voice room gift: including normal voice room and family voice room;',
  OfficialTaskPic_Level_text13: '2. Work period active reward: After the period ends, the number of active days will be counted, and the maximum reward you can get is 350 diamonds;',
  OfficialTaskPic_Level_text14: '3. All diamond rewards will be sent directly to your account during this work period;',
  OfficialTaskPic_Level_text15: '4. Every 100 diamonds = 1 USD',
  OfficialTaskPic_Level_text16: 'Daily reward (for gifts in chats, audio- and video calls, audio match)',
  OfficialTaskPic_Level_text17:
    'If you receive diamonds for gifts in chats, audio-and video calls, audio match and meet the daily target, you will receive the corresponding diamond reward. Diamond rewards will be added to your acount the following day automatically.',
  OfficialTaskPic_Level_text18: 'Daily reward (for gifts in chats and voice rooms)',
  OfficialTaskPic_Level_text19: 'Work period reward (for audio- and video calls)',
  OfficialTaskPic_Level_text20:
    '1. If you receive diamonds for gifts in chats and voice rooms and meet the daily target, you will receive the corresponding diamond reward. Diamond rewards will be added to your acount the following day automatically.',
  OfficialTaskPic_Level_text21:
    '2. If you receive diamonds for audio- and video calls  and meet the target by the end of the work period, you will receive the corresponding diamond reward. Diamond rewards will be added to your acount the following day after the end of the work period automatically.',
  OfficialTaskPic_Level_text22: '3. There are 2 work periods that last from the 1st till 15th of each month, and from 16th till 30th/31st of each month.',
  OfficialTaskPic_Level_text23: 'Daily reward 1 (Audio Party Room & Family Gift Diamond)',
  OfficialTaskPic_Level_text24: 'Daily reward 2 (Voice Call, Voice Match Diamond)',
  OfficialTaskPic_Level_text25: 'Work Period Reward ( Chat, Audio Party Room, Voice Calls, Audio Match)',
  OfficialTaskPic_Level_text26:
    '1. If you receive diamonds for gifts in chats, audio room and video calls, audio match and meet the weekly target, you will receive the corresponding diamond reward. Diamond rewards will be added to your acount in this salary term automatically.',
  OfficialTaskPic_Level_text27: '2. Salary Rate: 100 Diamond=1 USD;',
  OfficialTaskPic_Level_text28: '3. The diamonds got from daily rewards will not be counted to this diamond target;',
  OfficialTaskPic_Level_text29: 'Soyo official reward system 02.2024',
  OfficialTaskPic_Level_text30: 'Daily task 1: All calls + all gifts',
  OfficialTaskPic_Level_text31: 'Daily Mission 2: Message Reply',
  OfficialTaskPic_Level_text32: 'Daily Diamond Target',
  OfficialTaskPic_Level_text33: 'Our daily diamond target is shown above, and one day in our reward system is based on Turkish local time.',
  cms_report_text3: 'Report reason:',
  cms_report_text4: 'Report details:',
  cms_report_text5: 'Report screenshot:',
  cms_report_text6: '*Please verify the above information, so we can apply penalty for information.',
  cms_report_text7: 'Reporter Showid:',
  cms_report_text8: 'The reporter Showid:',
  cms_report_text9: 'Penalty method:',
  cms_report_text10: 'Penalty result:',
  cms_report_text11: 'Punishment history',
  cms_report_text12: 'Verbal warning',
  cms_report_text13: 'Banning method:',
  cms_report_text14: 'Reasons for banning:',
  cms_report_text15: 'Banning time:',
  cms_report_text16: 'Political sensitivity',
  cms_report_text17: 'Vulgar',
  cms_report_text18: 'Attack and abuse',
  cms_report_text19: 'Bloody violence',
  cms_report_text20: 'Advertising ',
  cms_report_text21: 'Suspected fraud',
  cms_report_text22: 'Illegal information',
  cms_report_text23: 'Minor',
  cms_report_text24: 'Other',
  cms_report_text25: 'Confirmation',
  cms_report_text26: 'Account sensitive operation, please confirm the information after the information',
  OfficialTask_dailyCard_text6: 'Completed',
  OfficialTask_rewardTime_text1: 'hours',
  OfficialTask_rewardTime_text2: 'minutes',
  delete_account_web_title: 'Dear SOYO User. Thanks for using and supporting our product. If you would like to delete your account, please check following steps to process:',
  delete_account_web1: '1. Open Application and enter main page, click the bottom right to enter Me Tab.',
  delete_account_web2: '2. Click settings after entering Me Tab.',
  delete_account_web3: '3. Click delete account after entering settings.',
  delete_account_web4: '4. Confirm first step for permanently delete account.',
  delete_account_web5: '5. Confirm second step for cannot login use same account.',
  delete_account_web6: '6. Confirm last step for deleting all corresponding personal information.',
  delete_account_web7:
    '7. After finishing all steps above. Your account has been deleted successfully, with all your personal information and account data. Our platform will no longer keep your data in our database.',
  delete_account_web: 'Delete Account',
  svip_introduce_1: '1.What is SVIP',
  svip_introduce_text1: 'SVIP is the distinguished level system of the platform. There are 8 levels in total, SVIP1-SVIP8. The higher the level, the more distinguished the status, and the more advanced rights and interests you enjoy.',
  svip_introduce_2: '2. How to become a SVIP',
  svip_introduce_text2: 'You can get SVIP experience points by recharging gold coins, 1 gold coin = 1 experience point. When the experience points reach different levels of SVIP, you can obtain the corresponding SVIP level.',
  svip_introduce_3: '3. How to calculate the validity period of SVIP',
  svip_introduce_text31: 'SVIP takes each natural month as a cycle, and on the 1st of each natural month, SVIP experience points are accumulated from 0. The corresponding SVIP level will be obtained according to the level reached by the SVIP experience value.',
  svip_introduce_text32: 'From the time you obtain SVIP status, your status and privileges will be retained until the end of the next month. (For example: if SVIP3 is reached on May 18, then the SVIP3 status will be extended to June 30.)',
  svip_introduce_text33: 'If your SVIP experience value reaches a higher level within a natural month, it will be upgraded immediately. (For example: if you reach SVIP3 on May 18th and SVIP4 on May 25th, you will immediately obtain SVIP4 status on May 25th and continue until the end of June 30th.)',
  svip_introduce_text34: 'When the natural month ends, the identity and privileges will be retained, but the SVIP experience points for the next month will be accumulated from 0. (For example: If you reach SVIP4 on May 25, you will still enjoy the identity and rights of SVIP4 throughout June, but you will start to accumulate SVIP experience points from 0 in June)',
  svip_introduce_4: '4.SVIP’s relegation and downgrade',
  svip_introduce_text41: 'At the beginning of each natural month, if you have a SVIP level and want to retain this SVIP level in the next month, you need to reach 50% of the SVIP level experience value before the end of the natural month, that is, you can retain this level in the next month, retain the identity and rights. (For example: when May starts, you are SVIP4, then you only need to reach 50% of the experience value of the SVIP4 level throughout May to retain the SVIP4 level until the end of June.)',
  svip_introduce_text42: 'If 50% of the SVIP level experience value is not reached within a natural month, it will be reduced by 1 level in the next month. (For example: at the beginning of May, you are SVIP4, and the experience value in the entire May does not reach 50% of the SVIP4 level, then June will drop by 1 level, starting from SVIP3.)',
  noble_introduce_title: 'Introduction to Aristocracy Rules',
  noble_introduce_welcome: 'Welcome to join our aristocratic system! Becoming an aristoctat will allow you to enjoy more unique privileges and benefits. The following is a helpful introduction to our aristocratic system:',
  noble_introduce_1: '1.What is Aristocracy?',
  noble_introduce_text1: 'Aristocracy is a special status symbol and is divided into seven status levels, namely knight, baron, viscount, earl, marquis, duke and king. Different levels of aristocracy enjoy different privileges.',
  noble_introduce_2: '2.How to become an aristocrat',
  noble_introduce_text2: 'Aristocracy can be purchased by recharging gold coins, and the purchase can be completed by selecting the corresponding level on the Aristocracy page. At the same time, you can also obtain aristocratic status through gifts from others.',
  noble_introduce_3: '3. Validity period calculation',
  noble_introduce_text3: 'The aristocratic status is valid for 30 days from the date of purchase, and you will enjoy aristocratic privileges during the validity period. The privileges will expire at the end of the validity period.',
  noble_introduce_4: '4. Daily coin delivery instructions',
  noble_introduce_text4: 'After purchasing Aristocracy through gold coins, you are eligible to receive gold coins as gifts every day. You will receive gold coins after signing in every day. If you do not sign in on that day, you will not be able to obtain the gold coins for that day. When gifting Aristocracy to others, the recipient will receive the right to receive daily coins.',
  noble_introduce_5: '5. Continuous purchase discounts',
  noble_introduce_text5: 'During the period of obtaining aristocratic status, and within 10 days after the aristocratic status expires, the same aristocratic status can be purchased again at a more favorable price. This plan is not automatic and requires you to purchase it manually to take effect.',
  noble_introduce_6: '6. Upgrading of Aristocracy',
  noble_introduce_text6: 'When you already have aristocratic status, you can purchase a higher-level Aristocracy and directly obtain the latest benefits. The remaining days of the existing Aristocracy will be merged into the latest level according to the remaining value, and the validity period of the new aristocratic level will be extended.',
  noble_introduce_7: '7. Sending Aristocracy as a gift',
  noble_introduce_text7: 'When giving Aristocracy to another person, the original price will be given to the other person, and the recipient will enjoy daily coins and other rights corresponding to the level. If the recipient already has aristocratic status, he will directly enjoy the high-level aristocratic status, and other levels will be converted according to the corresponding duration.',
  vip_introduce_title: 'Introduction to VIP rules',
  vip_introduce_welcome: 'Welcome to become a VIP! VIP will allow you to enjoy more privileges. The following is a help introduction about VIP:',
  vip_introduce_1: '1.What is VIP',
  vip_introduce_text1: 'VIP is a user growth system. There are currently 16 levels, VIP1-VIP16. The higher the VIP level, the more rights you will enjoy and the richer the props.',
  vip_introduce_2: '2.How to become a VIP',
  vip_introduce_text2: 'You can get VIP experience points by recharging gold coins, 1 gold coin = 1 experience point. The more gold coins you recharge, the more experience points you get, and the VIP will rise to a higher level.',
  vip_introduce_3: '3.VIP validity period',
  vip_introduce_text3: 'Once the VIP status is obtained, it is permanently valid and the VIP experience points are accumulated permanently and will not be cleared. As experience points increase, you will be promoted to a higher VIP level and enjoy more level benefits.',
  vip_introduce_4: '4. Use of VIP props',
  vip_introduce_text4: 'The VIP level props you obtain will always be stored in your backpack and can be worn at any time. When you upgrade, the low-level props you have obtained can still be used.',
  svip_introduce_title: 'Introduction to SVIP rules',
  svip_introduce_welcome: 'Welcome to become SVIP! SVIP will allow you to enjoy more privileges. The following is a helpful introduction to SVIP:',
  svip_introduce_text43: 'If no SVIP experience points are generated within a natural month, the SVIP level will drop by 3 levels in the next month. (For example: when May starts, you are SVIP4, and no experience points are generated throughout May, then June will drop by 3 levels, starting from SVIP1)',
  svip_introduce_5: '5. SVIP’s rights and interests',
  svip_introduce_text5: 'The higher the SVIP level, the more rights you enjoy. When the level changes, you will enjoy the benefits of the new level, and the prop rewards you receive will be overwritten by the new level. When upgrading or maintaining customized props, you need to contact customer service yourself and put forward customization requirements before they are issued. However, they will not be issued if you downgrade. The number of free messages and free calls will increase as the level increases. The corresponding amount will be issued when you upgrade or maintain the level, but will not be issued when you downgrade. The amounts distributed at each level are as follows:',
  svip_table1: 'SVIP level',
  vip_table1: 'VIP level',
  svip_table2: 'Free message',
  svip_table3: 'Free calls',
  Anonymity_name: 'Anonymity',
  Noble_Change_2: 'Purchase Conditions: Lower ranks can buy higher ranks, but higher ranks cannot buy lower ranks. For example, if you are currently a Count, you can only purchase Marquis, Duke, or King.',
  Noble_Change_3: 'Conversion Logic: If you are already an aristocrat, after purchasing another aristocratic rank, the remaining validity period of the old aristocratic rank will be converted to the new aristocratic rank based on the coin price ratio, and the old aristocratic rank will become invalid.',
  Noble_Change_4: 'Validity Period Calculation Formula: New purchased aristocracy validity period = current aristocratic rank remaining days * (current aristocratic rank price / purchased aristocratic rank price) + 30, the final number of days is rounded down.',
  Noble_Change_5: "Example: If you are currently a Viscount (99990 coins/month), with 13 days left, and you purchase a Count (199990 coins/month) today, then after payment, the Count's validity period will be 13 * (99990/199990) + 30 = 6.5 rounded down + 30 = 36 days.",
  rich_list: 'Rich Ranking',
  lucky_list: 'Lucky Ranking',
  lucky_daily: 'Daily',
  lucky_weekly: 'Weekly',
  lucky_faq1: 'How to play',
  lucky_faq2: 'Game Rules',
  lucky_faq3: '1. After giving a lucky gift, you have a chance to get 1-1000 times the gold coin reward;',
  lucky_faq4: '2. The more lucky gifts you send in Combo or Batch, the greater the chance of winning;',
  lucky_faq5: '3. Gold coin rewards will be distributed to the account balance in real time, and winning details can be viewed in the lucky gift record;',
  lucky_faq6: '4. Every time a lucky gift is given, the anchor will also receive a certain proportion of diamonds.',
  lucky_faq7: 'Ranking Rules',
  lucky_faq8: '1. Statistical Rules',
  lucky_faq9: 'The ten users who spend the most gold coins participating in the daily and weekly gameplay will be on the rich ranking, and the ten users with the highest winning multiples will be on the lucky ranking.',
  lucky_faq10: '2. Statistical Cycle',
  lucky_faq11: 'Daily ranking: data from 0:00 to 24:00 every day',
  lucky_faq12: 'Weekly ranking: data from 0:00 every Monday to 24:00 Sunday',
  lucky_faq13: '3. Ranking Rewards',
  lucky_faq14: 'The top ten on the daily and weekly rankings will receive rewards as shown below',
  lucky_faq15: 'Daily ranking rewards',
  lucky_faq16: 'Ranking',
  lucky_faq17: 'Weekly ranking rewards',
  Charge_Same_1: 'You have sent the same amount of recharge to this user recently and the transaction was successful. Do you want to proceed again?',
  Charge_Same_2: 'Cancel Payment',
  Charge_Same_3: 'Proceed with Payment',
  Noble_Change_2: 'Purchase Conditions: Lower ranks can buy higher ranks, but higher ranks cannot buy lower ranks. For example, if you are currently a Count, you can only purchase Marquis, Duke, or King.',
  Noble_Change_3: 'Conversion Logic: If you are already an aristocrat, after purchasing another aristocratic rank, the remaining validity period of the old aristocratic rank will be converted to the new aristocratic rank based on the coin price ratio, and the old aristocratic rank will become invalid.',
  Noble_Change_4: 'Validity Period Calculation Formula: New purchased aristocracy validity period = current aristocratic rank remaining days * (current aristocratic rank price / purchased aristocratic rank price) + 30, the final number of days is rounded down.',
  Noble_Change_5: "Example: If you are currently a Viscount (99990 coins/month), with 13 days left, and you purchase a Count (199990 coins/month) today, then after payment, the Count's validity period will be 13 * (99990/199990) + 30 = 6.5 rounded down + 30 = 36 days.",
  Registr_optimization_1: 'Registration and login issues',
  AnchorCenter_biList_interaction_text1: 'Interaction',
  AnchorCenter_biList_interaction_text2: 'Initiated Chats (UV)',
  AnchorCenter_biList_interaction_text3: 'Reply Rate (UV)',
  AnchorCenter_biList_interaction_text4: 'Reply Rate (PV)',
  AnchorCenter_biList_interaction_text5: 'Response Rate (UV)',
  AnchorCenter_biList_interaction_text6: 'Messages Received (UV)',
  AnchorCenter_biList_interaction_text7: 'Messages Replied (UV)',
  AnchorCenter_biList_interaction_text8: 'Response Rate (UV)',
  AnchorCenter_biList_interaction_text9: 'Messages Sent (UV)',
  AnchorCenter_biList_interaction_text10: 'Your Messages Replied (UV)',
  AnchorCenter_biList_interaction_text11: 'Reply Rate (UV)',
  AnchorCenter_biList_interaction_text12: 'Messages Sent (PV)',
  AnchorCenter_biList_interaction_text13: 'Your Messages Replied (PV)',
  AnchorCenter_biList_interaction_text14: 'Reply Rate (PV)',
  AnchorCenter_biList_interaction_Popup1: "You received messages from 'x' users",
  AnchorCenter_biList_interaction_Popup2: "You replied to 'n' out of 'x' users who messaged you",
  AnchorCenter_biList_interaction_Popup3: 'Example: You received messages from 100 users, and you effectively replied to 90 of them. Your effective reply rate (UV) = 90/100 = 90%',
  AnchorCenter_biList_interaction_Popup4: 'Message Reply Percentage (UV)',
  AnchorCenter_biList_interaction_Popup5: 'Effective Reply Rate: Only counts users who were replied to before timeout',
  AnchorCenter_biList_interaction_Popup6: 'Got it',
  AnchorCenter_biList_interaction_Popup7: "You sent messages to 'x' users",
  AnchorCenter_biList_interaction_Popup8: "Out of 'x' users you messaged, 'n' replied to you",
  AnchorCenter_biList_interaction_Popup9: 'Percentage of Users Who Replied (UV)',
  AnchorCenter_biList_interaction_Popup10: 'Example: You sent messages to 100 users, and 50 of them replied. Your effective reply rate (UV) = 50/100 = 50%',
  AnchorCenter_biList_interaction_Popup11: 'Effective Reply Rate: Only counts replies received before timeout',
  AnchorCenter_biList_interaction_Popup12: "You sent 'x' messages",
  AnchorCenter_biList_interaction_Popup13: "Out of 'x' messages you sent, 'n' were effectively replied to",
  AnchorCenter_biList_interaction_Popup14: 'Percentage of Your Messages Replied (PV)',
  AnchorCenter_biList_interaction_Popup15: 'Example: You sent 100 messages, and 70 of them were effectively replied to. Your effective reply rate (PV) = 70/100 = 70%',
  AnchorCenter_biList_interaction_Popup16: 'Effective Reply Rate: Only counts replies received before timeout',
  program_list_24_ios: 'Delete',
  withdraw_text_26: 'Please verify the correct payment information. Incorrect information will result in withdrawal failure, and additional fees will be borne by the user.',
  withdraw_text_29: 'Tip:',
  withdraw_text_43: 'There are unfinished orders for this payment method and cannot be modified or deleted temporarily.',
  withdraw_text_44: 'Submitted successfully!',
  payment_bind_2: 'Bank card',
  payment_bind_2_GPAY: 'Bank card',
  payment_bind_3: 'Bank name',
  payment_bind_4: 'Name',
  payment_bind_5: 'Mail',
  payment_bind_6: 'Address',
  payment_bind_7: '10 digits or PL plus 10 digits',
  payment_bind_8: 'Starts with TR + 24 digits',
  payment_bind_9: '{n} Must',
  payment_bind_10: 'Delete successful',
  payment_bind_11: 'First name/Given name',
  payment_bind_12: 'Last name/Surname',
  Tvwall_gift_9: ' Complete gift wishlists worth more than or equal to 2,000  coins to be displayed on the TV wall',
  Tvwall_gift_10: 'Entry lock screen conditions:',
  Tvwall_gift_32: 'Should giving gifts be shown on TV?',
  Tvwall_gift_33: '(Click to select to take effect immediately)',
  Tvwall_gift_11: 'Lock screen for 10 seconds: a wish gift is completed and the value of the wish gift is between 2000  coins and 9999  coins;',
  Tvwall_gift_12: 'Lock screen for 20 seconds: Completed a wish gift and the value of the wish gift is between 10,000  coins and 49,999 coins;',
  Tvwall_gift_13: 'Lock screen for 60 seconds: Completed a wish gift and the value of the wish gift is more than 50,000  coins',
  Tvwall_gift_14: 'Conditions for appearing on the World TV Wall:',
  Tvwall_gift_15: 'Ordinary record: Completed a wish gift and the value of the wish gift is between 2000  coins and 9999  coins',
  Tvwall_gift_16: 'Luxurious record: Completed a wish gift and the value of the wish gift is greater than or equal to 10,000  coins',
  Tvwall_gift_17: 'On the TV wall',
  Tvwall_gift_18: 'Anonymous on the TV wall',
  Tvwall_gift_19: 'Not on the TV wall',
  Admob_text7: 'In preparation',
  Admob_text8: 'Video is not ready yet',
  EXP: 'EXP',
  'Terms of Service': 'Terms of Service',
  'Privacy Policy': 'Privacy Policy',
  Experience_Card_Statement: '*Experience cards do not have this benefit',
  Official_website_payment_1: 'SOYO Online Recharge',
  Official_website_payment_2: 'Recharge ID',
  Official_website_payment_3: 'Enter the ID',
  Official_website_payment_4: 'what is ID？',
  Official_website_payment_5: "Please check first to protect account's privacy and security.",
  Official_website_payment_6: 'Query',
  Official_website_payment_7: 'Notice to Users',
  Official_website_payment_8: "1.Don't purchase SOYO coins through unofficial or unauthorized websites to avoid unnecessary disputes.",
  Official_website_payment_9: "2.Don't trust any unofficial information posted on other websites, communication channels or communication groups. All event information is subject to official announcement via the official website or in-system notice.",
  Official_website_payment_10: '3.Any offline transactions are not supported.',
  Official_website_payment_11: "4.Don't disclose your account information to anyone else for any reason or in any method.",
  Official_website_payment_12: '5.SOYO shall not be held liable for any disputes between Users and the third party.',
  Official_website_payment_13: "6.If there's any problem with recharging,please contact customer service or Email us.",
  Official_website_payment_14: 'Anti-money Laundering',
  Official_website_payment_15: '1.The user must ensure that you have a legal identity and capacity to use the services provided by us and will not participate in or be associated with any criminal activity.',
  Official_website_payment_16: '2.The user account is for your exclusive use and belongs to you alone. You may not transfer, rent or otherwise dispose of your account to any third party.',
  Official_website_payment_17: '3.The user must ensure that all money used for recharges is obtained through legal means and is not at any time associated with criminal activity or behavior.',
  Official_website_payment_18: 'Method of Payment',
  Official_website_payment_19: 'Currently using {%s} payment channel',
  Official_website_payment_20: 'Recharge Option',
  Official_website_payment_21: 'Change',
  Official_website_payment_22: 'Account ID does not exist!',
  Official_website_payment_23: 'No supported payment methods yet',
  Official_website_payment_24: 'Feedback your desired payment method',
  Official_website_payment_25: 'Querying transaction results',
  Official_website_payment_26: 'Recharge result to be confirmed',
  Official_website_payment_27: 'The recharge result may be delayed. Please enter the App to check the account recharge record. If the account does not arrive for a long time, please contact customer service.',
  Official_website_payment_28: 'Refresh',
  Official_website_payment_29: 'Recharge failed',
  Official_website_payment_30: 'Help information:',
  Official_website_payment_31: '1. Please confirm whether the amount and payment method are correct, including personal information, credit card number, validity period, CVV code, etc.',
  Official_website_payment_32: '2. Please check whether the account balance is sufficient.',
  Official_website_payment_33: '3. Check whether the network connection is stable. Sometimes network problems may cause payment failure.',
  Official_website_payment_34: '4. Try changing to other payment methods or try recharging later.',
  Official_website_payment_35: '5. If the problem is still not resolved, it is recommended to contact the customer service of the recharge platform for further help.',
  Official_website_payment_36: 'Recharge successful',
  Official_website_payment_37: 'Please enter the App to view the account recharge record',
  Official_website_payment_38: 'Link timeout fails',
  Official_website_payment_39: 'The link has expired. Please re-create the payment link in the App.',
  payment_bind_13: 'Contract Address',
  payment_bind_14: 'Epay Email',
  payment_bind_15: 'Note: TRC-20 protocol',
  payment_bind_16: 'Note: Ensure your Epay account is verified',
  payment_bind_17: 'Contract address starts with T',
  payment_bind_18: 'Cannot exceed 40 characters',
  payment_bind_19: 'Must match your Epay account email',
  payment_bind_20: 'Enter email address',
  payment_bind_21: 'Gcash account',
  payment_bind_22: 'Must be 11 digits starting with 09',
  payment_bind_23: 'Only allow English letters, spaces, hyphens (-), periods (.), and commas (,) and less than 100 characters.',
  task_miss: 'miss',
  re_signing: 'Re-signing',
  anchor_task_txt1: 'Visit old friends (and get replies from them) for {%s1} times and get {%s2} 💎',
  anchor_task_txt2: 'The number of sessions with new users > 29 reaches {%s1} times, get {%s2} 💎',
  task_txt21: 'Daily check-in',
  task_txt22: 'day',
  task_txt23: 'Sign in',
  task_txt24: 'task list',
  task_txt25: 'Anchor tasks',
  task_txt31: 'Signed in',
  sign_in_text3_new: 'Sign-in 2x the reward',
  Double_Rewards_text1: 'Become SVIP for Double Rewards!',
  chat_skill_title: 'Must-Read for Anchors',
};

export default EN_US;