import { MapItem, UserOptionalMsg } from './protobuf/events_pb';
import AppCore from '@/appcore';
import thinkingdata from 'thinkingdata-browser';
import { getValueFromUA } from '@/asset/js/utils';
import { getOsType } from '@/asset/js/utils';
import { isMyBoboup } from '@/asset/js/utils';
import axios from 'axios';

// function getValueFromUA(key) {
//   // const userAgent = 'Mozilla/5.0 (Linux; Android 10; Infinix X680B Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/95.0.4638.74 Mobile Safari/537.36 company/soulu client/soulu country/HK lang/en version/1.0.1 versionInt/7 android/10 manufacturer/HUAWEI model/EML-AL00 channel/null deviceId/7ac76048-5547-42cb-b9c1-b55bf50e8e4b userid/1465223513640239105 sessionId/d1d9c079c763c265913373934997aab5';
//   const userAgent = navigator.userAgent || '';
//   const test = new RegExp(`(?:company\/soulu\\sclient\/soulu)(?:.*)\\s+${key}\/([^\\s]+)`);
//   const p = userAgent.match(test);
//   if (p && p[1]) {
//     return p[1];
//   }
//   return '';
// }
let platform = 'wap';

let userid = getValueFromUA('userid');

/**
 * 获取公共参数
 * deviceId
 * userId
 * cTime
 */
export function getCommonParam() {
  return {
    platform,
    eventTime: String(new Date().getTime()),
    country: window.config.country,
    language: window.config.lang,
  };
}

// function getOsType() {
//   const userAgent = navigator.userAgent.toLowerCase();
//   if (userAgent.indexOf('android') >= 0 || userAgent.indexOf('adr ') >= 0) {
//     return 'android';
//   } else if (userAgent.indexOf('ios') >= 0 || userAgent.indexOf('iphone') >= 0 || userAgent.indexOf('ipad') >= 0 || userAgent.indexOf('ipod') >= 0) {
//     return 'ios';
//   } else {
//     return 'wap';
//   }
// }
//数数配置
var config = {
  appId: process.env.thinkingdataId,
  serverUrl: 'https://data.newlumos.com',
};

if (!isMyBoboup()) {
  //数数初始化SDK
  thinkingdata.init(config);
  thinkingdata.setSuperProperties(getCommonParam()); //设置公共事件属性
  userid && thinkingdata.login(userid);
}

function setMapItem(items = {}, userOptionalMsg) {
  const keys = Object.keys(items);
  for (let key of keys) {
    const mapItem = new MapItem();
    mapItem.setKey(key);
    mapItem.setValue(items[key]);
    userOptionalMsg.addItem(mapItem);
  }
}

/**
 *
 * @param msg protobuff定义的数据
 * 对饮 .proto文件中定义的 type主要是拼接数据的时候用
 */
function send(msg, type) {
  // 消息转成字节
  const bytes = msg.serializeBinary();
  // const message2 = UserOptionalMsg.deserializeBinary(bytes);
  const adcPath = process.env.NODE_ENV === 'production' ? 'http://adc.souluapp.com/' : 'http://test-adc.souluapp.com/';
  // 6是type和size的长度
  const size = bytes.length + 6;
  // 32位数组的size
  const lenArr = new Uint32Array(1);
  lenArr[0] = size;
  // 16位数组的type
  const typeArr = new Uint16Array(1);
  typeArr[0] = type;
  const lenInt = new Uint8Array(lenArr.buffer);
  const typeInt = new Uint8Array(typeArr.buffer);
  const totalInt = new Uint8Array(size); // LE
  let i = 0;
  for (const u of lenInt.reverse()) {
    // LE -> BE
    totalInt[i] = u;
    i++;
  }

  for (const u of typeInt.reverse()) {
    totalInt[i] = u;
    i++;
  }
  for (const u of bytes) {
    totalInt[i] = u;
    i++;
  }
  axios({
    url: `${adcPath}report/`,
    method: 'post',
    data: totalInt,
  });
}

/**
 *
 * @param {string} eventName
 * @param {Object} items  一个object，可以应该根据文档定义写，
 * @pram {number} type 对应.proto文件中的类型
 * type默认是userOptionalMsg,目前也只用到这个自定义事件
 * 如 {
 *  deviceId: 111,
 *  userId: 111
 * }
 */
export default function action(eventName, items = {}, type = 1004) {
  const { actionParam, itemId, extra, extra1, extra2, extra3, extra4, label, refer, readTime, ...otherItems } = items;
  let curItems = { eventName, actionParam, itemId, extra, extra1, extra2, extra3, extra4, label, refer, readTime };
  if (getOsType() !== 'android') {
    curItems = { actionName: eventName, eventParams: actionParam, action_sourceId: itemId, additional: extra, additional1: extra1, additional2: extra2, additional3: extra3, additional4: extra4, action_label: label, action_from: refer, readTime };
  }
  if (AppCore.getAppName() === 'lumos') {
    // if (getOsType() == 'android') {
    //   AppCore.report({ event: JSON.stringify({ eventName, actionParam, itemId, extra, extra1, extra2, extra3, extra4, label, refer, readTime }) });
    // } else {
    //   AppCore.report({ event: JSON.stringify({ actionName: eventName, eventParams: actionParam, action_sourceId: itemId, additional: extra, additional1: extra1, additional2: extra2, additional3: extra3, additional4: extra4, action_label: label, action_from: refer, readTime }) }, (val) => {console.log(val)});
    // }
    AppCore.report({
      event: JSON.stringify(curItems)
    });
    return;
  }
  if (!isMyBoboup()) {
    thinkingdata.track(eventName, curItems); //事件名称
    const msg = new UserOptionalMsg();
    msg.setEventname(eventName);
    msg.setActionParam(actionParam);
    msg.setItemId(itemId);
    msg.setExtra(extra);
    msg.setExtra1(extra1);
    msg.setExtra2(extra2);
    msg.setExtra3(extra3);
    msg.setExtra4(extra4);
    msg.setLabel(label);
    msg.setRefer(refer);
    setMapItem(otherItems, msg);
    send(msg, type);
  }
}
